import React from 'react';
import {
	Avatar,
	Button,
	Col,
	Form,
	Input,
	List,
	message,
	Row,
	Space, Tag, Tooltip,
} from 'antd';
import {
	type Attachment,
	type Comment as CommentModel,
	type CommentParentType,
	commentTypeLabels,
	type User,
	VisibilityType,
	type AttachmentData,
} from 'app/models';
import {type UploadFile} from 'app/models/upload-file';
import {checkFileErr, getS3Url, toTitleCase} from 'app/helpers';
import {PaperClipOutlined, UserOutlined} from '@ant-design/icons';
import moment from 'moment';
import {FileUpload} from 'app/components/Common/FileUpload';
import {CommentComponent} from './CommentComponent';

type Props = {
	parentId: number | string;
	parentType: CommentParentType;
	authUser?: User;
	comments: CommentModel[];
	canComment: boolean;
	showVisibility?: boolean;
	showUser?: boolean;
	saveComment?: (data: any) => void;
};

export const CommentBlock: React.FC<Props> = ({
	parentId,
	parentType,
	authUser,
	comments,
	canComment,
	showVisibility,
	showUser,
	saveComment,
}) => {
	const [content, setContent] = React.useState<string>('');
	const [fileList, setFileList] = React.useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = React.useState<Record<string, string>>({});
	const [visibleToClient, setVisibleToClient] = React.useState<boolean>(false);
	const [visibleToVendor, setVisibleToVendor] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (content) {
			setContent('');
		}
	}, [comments]);

	const onSaveComment = () => {
		if (!saveComment) {
			return;
		}

		let visibility: string;
		if (visibleToClient && visibleToVendor) {
			visibility = VisibilityType.EVERYONE as string;
		} else if (visibleToClient) {
			visibility = VisibilityType.CLIENT as string;
		} else if (visibleToVendor) {
			visibility = VisibilityType.VENDOR as string;
		} else {
			visibility = VisibilityType.INTERNAL as string;
		}

		if (!content) {
			void message.error('Enter reply');
			return;
		}

		checkFileErr(fileList);
		const attachments = fileList.map((file: UploadFile) => {
			const x: AttachmentData = {
				name: file.name,
				key: `${filePrefixKeys[file.uid]}/${file.name}`,
			};
			return x;
		});

		const data = {
			visibility,
			content,
			attachments,
			parentId,
			parentType,
		};
		saveComment(data);
	};

	return (
		<Row>
			{comments?.length ? (
				<Col span={24}>
					<List
						className='comment-list'
						itemLayout='horizontal'
						dataSource={comments}
						renderItem={comment => (
							<li>
								<CommentComponent
									author={showUser ? (
										<span style={{color: '#222222', fontWeight: 'bold', fontSize: '14px'}}>
											{comment.createdUser ? comment.createdUser.name : 'User'}
										</span>
									) : undefined}
									avatar={showUser ? (<Avatar icon={<UserOutlined/>}/>) : undefined}
									content={(
										<Row>
											<Col span={24}>
												{comment.visibility && comment.visibility !== VisibilityType.INTERNAL && (
													<small>
														{`Visible to ${toTitleCase(comment.visibility ?? '')}`}
													</small>
												)}
												{comment.commentType ? (
													<p><Tag color={'#108ee9'}>{commentTypeLabels[comment.commentType] ?? comment.commentType}</Tag></p>
												) : []}
												<p style={{whiteSpace: 'pre-line'}}>{comment.content}</p>
											</Col>
											{comment.attachments?.length ? comment.attachments.map((attachment: Attachment, ix: number) => (
												<Col span={24} key={ix}>
													<PaperClipOutlined/>
													{' '}
													<a href={getS3Url(attachment.key ?? '')} target='_blank' rel='noreferrer'>
														{attachment.name}
													</a>
												</Col>
											)) : []}
										</Row>
									)}
									datetime={(
										<span style={{color: '#444444', fontSize: '14px'}}>
											<Space>
												{moment(comment.createdAt).fromNow()}
												{comment.visibility && comment.visibility !== VisibilityType.INTERNAL ? (
													<Tooltip placement='topLeft' title={'Comment visible to outside team member'}>
														<Tag color={'#f50'}>External</Tag>
													</Tooltip>
												) : []}
											</Space>
										</span>
									)}
								/>
							</li>
						)}
					/>
				</Col>
			) : []}

			{canComment ? (
				<Col span={24}>
					<CommentComponent
						author={(
							<span style={{color: '#222222', fontWeight: 'bold', fontSize: '14px'}}>
								{authUser?.name ?? 'User'}
							</span>
						)}
						avatar={(<Avatar icon={<UserOutlined/>}/>)}
						content={(
							<div>
								<Form.Item>
									<Input.TextArea
										rows={2}
										onChange={(e: any) => {
											setContent(String(e.target.value));
										}}
										defaultValue={content}
										value={content}
									/>
								</Form.Item>
								<Form.Item>
									<FileUpload
										prefix={'comment'}
										fileList={fileList}
										filePrefixKeys={filePrefixKeys}
										onFileChange={((fList, fPrefixKeys) => {
											setFileList(fList);
											setFilePrefixKeys(fPrefixKeys);
										})}
									/>
								</Form.Item>
								<Form.Item>
									<Button onClick={onSaveComment} type='primary'>
                    Submit
									</Button>
								</Form.Item>
							</div>
						)}
					/>
				</Col>
			) : []}
		</Row>
	);
};
